@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-dark-smoky-black dark:bg-light-smoky-black font-poppins;
  }
}

@layer components {
  .sidebar-container {
    @apply bg-dark-eerie-black-2 dark:bg-light-eerie-black-2 border border-[hsl(228,100%,72%)] rounded-lg p-4 shadow-custom-1 z-10;
  }

  .nav-link {
    @apply text-dark-light-gray dark:text-light-light-gray text-fs-6 py-5 px-2 
    transition-colors duration-300 
    hover:text-dark-light-gray-70 dark:hover:text-light-light-gray-70
    lg:text-[15px] lg:py-5 lg:px-5;
  }

  .nav-link.active {
    @apply text-dark-blue-crayola dark:text-light-blue-crayola;
  }
}
